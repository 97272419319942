import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { NavLink } from "react-router-dom";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import { Link } from "react-scroll";

const pages = [
  { name: "الرئيسية", id: "Hero" },
  { name: "سياسة", id: "policy" },
  { name: "اقتصاد", id: "economy" },
  { name: "ثقافة", id: "culture" },
  { name: "الدين والحياة", id: "religion" },
  { name: "رأي", id: "opinion" },
  { name: "اتجاهات نظرية", id: "trends" },
  { name: "بيانات", id: "data" },
  { name: "مقابلات", id: "interviews" },
  { name: "ملتيميديا", id: "multimedia" },
  { name: "حقوق وحريات", id: "rights" },
  { name: "من نحن", id: "whous" },
];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [activePage, setActivePage] = React.useState(null);
  const handleClick = (pageId) => {
    setActivePage(pageId);
  };
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar
      sx={{
        bgcolor: "white",
        direction: "rtl",
        color: "#000",
        boxShadow: "none",
        borderTop: "1px solid black",
        borderBottom: "1px solid black",
      }}
      position="static"
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "center",
            }}
          >
            {pages?.map((page) => (
              page.name === "الرئيسية" ? (
                <NavLink
                  key={page.id}
                  to="/" // Root link for "الرئيسية"
                  className="button-link"
               
                  style={{
                    margin: "10px",
                    color: activePage === page.id ? "red" : "black",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  activeClass="active"
                  onClick={() => handleClick(page.id)}
                >
                  {page.name}
                </NavLink>
              ) : (
                <NavLink
                  key={page.id}
                  to={`/articles?category=${page.name}`}
                  smooth={true}
                  duration={500}
                  className="button-link"
                  style={{
                    margin: "10px",
                    color: activePage === page.id ? "red" : "black",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  activeClass="active"
                  onClick={() => handleClick(page.id)}
                >
                  {page.name}
                </NavLink>
              )
            ))}

          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="النسخة الإلكترونية">
              <IconButton
                onClick={handleOpenUserMenu}
                sx={{
                  p: 0,
                  ":hover": {
                    bgcolor: "white", // theme.palette.primary.main
                    color: "black",
                  },
                }}
              >
                <ImportContactsIcon sx={{ color: "#000" }} />
                <span style={{ fontSize: "12px", marginRight: "5px" }}>
                  جريدة المستقلون
                </span>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
