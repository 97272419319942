import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import theoriticalTrends from "../../../assets/theoriticaltrends.png";
import HeroCard from "../HeroCard/HeroCard";
import NewsCard from "../NewsCard/NewsCard";
import politics from "../../../assets/theoriticaltrends1.png";
import war from "../../../assets/theoriticaltrends2.png";
import novel from "../../../assets/theoriticaltrends3.png";
import SectionTitle from "../SectionTItle/SectionTitle";
import useFetch from "../../../hooks/useFetch";

function TheoreticalTrends() {
  const imgUrl ="https://www.tanaghomtech.com/magazine/storage/app/public/"
  const [newslist] = useFetch("https://www.tanaghomtech.com/magazine/public/api/article?include=category,writer,country&filter[category_id]=7")
   if (!newslist || newslist.length < 4) return null;
  return (
    <>
      <SectionTitle sectionName="اتجاهات نظرية" />

      <Box
        id="trends"
        sx={{
          flexGrow: 1,
          height: { sx: "auto", md: "100vh" },
        }}
      >
        <Grid container spacing={1}>
        {newslist?.slice(1).map((article) => (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box>
              <HeroCard
                title={article.title}
                category={article.category}
                date={article.created_at}
                backgroundImage={imgUrl + article.image}
                size="70vh"
                categoryColor="#FF5733"
              />
            </Box>
          </Grid>
        ))}
       
        </Grid>
      </Box>
    </>
  );
}

export default TheoreticalTrends;
