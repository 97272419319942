import axios from "axios";
import axiosRetry from "axios-retry";
import { useEffect, useState } from "react";

axiosRetry(axios, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

function useFetch(URL) {
  const [newslist, setNewsList] = useState([]);
  
  useEffect(() => {
    const getPolitics = async () => {
      try {
        const response = await axios.get(URL);
        setNewsList(response?.data?.data || []);
        console.log("hi custom", response?.data?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getPolitics();
  }, [URL]);

  return [newslist];
}

export default useFetch;
