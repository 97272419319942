import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import HeroCard from "../HeroCard/HeroCard";
import SectionTitle from "../SectionTItle/SectionTitle";
import useFetch from "../../../hooks/useFetch";

const Multimedia = () => {
  const imgUrl ="https://www.tanaghomtech.com/magazine/storage/app/public/"
  const [newslist] = useFetch("https://www.tanaghomtech.com/magazine/public/api/article?include=category,writer,country&filter[category_id]=10")
   if (!newslist || newslist.length < 5) return null;
  return (
    <>
      <SectionTitle sectionName="ملتيميديا" />

      <Box
        id="multimedia"
        sx={{
          flexGrow: 1,
          marginTop: "1rem",
          marginBottom: "2rem",
          height: { sx: "auto", md: "100vh" },
        }}
      >
        <Grid container spacing={2}>
          {newslist.map(news=>(
 <Grid item xs={12} md={6}>
 <HeroCard
   category={news.category.categoryName}
   backgroundImage={imgUrl + news.imageLink}
   size="100vh"
   categoryColor="#0A6522"
 />
</Grid>
          ))}
         
        </Grid>
      </Box>
    </>
  );
};

export default Multimedia;
