import React, { useState , useEffect} from "react";
import Container from "@mui/material/Container";
import { Grid, Box } from "@mui/material";

import backgroundImage from "../../../assets/background.jpeg";
import People from "../../../assets/people.png";
import Palmyra from "../../../assets/Palmyra.png";
import Dama from "../../../assets/dama.png";
import Nature from "../../../assets/nature.png";

import HeroCard from "../HeroCard/HeroCard";
import axios from "axios";

export default function Hero() {
  const [newsData, setNewsData] = useState([]);
  const [newsData2, setNewsData2] = useState([]);
  const [newslist, setNewsList] = useState([]);
  useEffect(() => {
    const getNew = () => {
      axios
        .get(
          `https://www.tanaghomtech.com/magazine/public/api/getlatestArticlebycategory/1?include=category,writer,country`
        )
        .then((response) => {
          setNewsData(response?.data.data);
          console.log(response?.data.data);
        
        })
        .catch((error) => {
          console.error(
            "There has been a problem with your fetch operation:",
            error
          );
        
        });
    };

    const getNew2 = () => {
     

      axios
        .get(
          `https://www.tanaghomtech.com/magazine/public/api/getlatestArticlebycategory/5?include=category,writer,country`
        )
        .then((response) => {
          setNewsData2(response?.data.data);
          console.log(response?.data.data);
        
        })
        .catch((error) => {
          console.error(
            "There has been a problem with your fetch operation:",
            error
          );
        
        });
    };

    getNew();
    getNew2();
  },[]);

  useEffect(() => {
    const getPolitics = () => {
          axios
      .get(
        `https://www.tanaghomtech.com/magazine/public/api/article?include=category,writer,country`
      )
      .then((response) => {
        setNewsList(response?.data.data);
        console.log(response?.data.data);
        
      })
      .catch((error) => {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
        
      });
  };

    getPolitics();
  },[]);

  
  return (
    <Box
      sx={{
        flexGrow: 1,
        marginTop: "1rem",
        height: { sx: "auto", md: "100vh" },
      }}
    >
      <Grid container spacing={1}>
        {/* First card on the left side */}
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <Box>
            <HeroCard
              title={newsData?.title}
              category="سياسة"
              date={new Date(newsData?.created_at).toLocaleTimeString("default", {
                year: "numeric", month: "numeric", day: "numeric", time: "numeric"
              })}
              backgroundImage={"https://www.tanaghomtech.com/magazine/storage/app/public/"+newsData?.imageLink}
              size="91vh"
              categoryColor="#FF5733" // Example of passing a dynamic color
              link={"/article/"+newsData?.id}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <Grid container spacing={1}>
            {/* Top two cards on the right */}
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <HeroCard
                title={newsData2.title}
                category="الدين والحياة"
                date={new Date(newsData.created_at).toLocaleTimeString("default", {
                  year: "numeric", month: "numeric", day: "numeric", time: "numeric"
                })}
                backgroundImage={"https://www.tanaghomtech.com/magazine/storage/app/public/"+newsData2.imageLink}
                size="45vh"
                categoryColor="#FF5733"
                link={"/article/"+newsData2.id}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <HeroCard
                title={newslist[0]?.title}
                category="سياسة"
                date={new Date(newslist[0]?.created_at).toLocaleTimeString("default", {
                  year: "numeric", month: "numeric", day: "numeric", time: "numeric"
                })}
                backgroundImage={"https://www.tanaghomtech.com/magazine/storage/app/public/"+newslist[0]?.imageLink}
                size="45vh"
                categoryColor="#FF5733"
                 link={"/article/"+newslist[0]?.id}
              />
            </Grid>

            {/* Bottom card under the top two, spanning their combined width */}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <HeroCard
                title={newslist[1]?.title}
                category="سياسة"
                date={new Date(newslist[1]?.created_at).toLocaleTimeString("default", {
                  year: "numeric", month: "numeric", day: "numeric", time: "numeric"
                })}
                backgroundImage={"https://www.tanaghomtech.com/magazine/storage/app/public/"+newslist[1]?.imageLink}
                size="45vh"
                categoryColor="#FF5733"
                 link={"/article/"+newslist[1]?.id}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
