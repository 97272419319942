import React, { useState, useEffect } from 'react';
import { Box, Typography, Card, CardMedia, Chip } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { InfoRounded } from '@mui/icons-material';
import Topbar from '../Components/Home/TopBar/Topbar';
import Navbar1 from '../Components/Home/Navbar/Navbar1';
import Navbar2 from '../Components/Home/Navbar/Navbar2';
import { Link, useLocation } from 'react-router-dom';
import Footer from "../Components/Home/Footer/Footer";
import moment from 'moment'
const ArticlePage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const category = queryParams.get('category');

  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const imgUrl = "https://www.tanaghomtech.com/magazine/storage/app/public/"
  // Fetch articles based on the category
  useEffect(() => {
    const fetchArticles = async () => {
      setLoading(true);
      try {
        let apiUrl = '';
        if (category === 'سياسة') {
          apiUrl = 'https://www.tanaghomtech.com/magazine/public/api/article?include=category,writer,country&filter[category_id]=1'; // Replace with your politics API
        } else if (category === 'اقتصاد') {
          apiUrl = 'https://www.tanaghomtech.com/magazine/public/api/article?include=category,writer,country&filter[category_id]=2'; // Replace with your economy API
        } else if (category === 'ثقافة') {
          apiUrl = 'https://www.tanaghomtech.com/magazine/public/api/article?include=category,writer,country&filter[category_id]=3'; // Replace with your economy API
        }  else if (category === 'الدين والحياة') {
          apiUrl = 'https://www.tanaghomtech.com/magazine/public/api/article?include=category,writer,country&filter[category_id]=5'; // Replace with your economy API
        }
        else if (category === 'رأي') {
          apiUrl = 'https://www.tanaghomtech.com/magazine/public/api/article?include=category,writer,country&filter[category_id]=6'; // Replace with your economy API
        } else if (category === 'اتجاهات نظرية') {
          apiUrl = 'https://www.tanaghomtech.com/magazine/public/api/article?include=category,writer,country&filter[category_id]=7'; // Replace with your economy API
        } else if (category === 'بيانات') {
          apiUrl = 'https://www.tanaghomtech.com/magazine/public/api/article?include=category,writer,country&filter[category_id]=8'; // Replace with your economy API
        } else if (category === 'مقابلات') {
          apiUrl = 'https://www.tanaghomtech.com/magazine/public/api/article?include=category,writer,country&filter[category_id]=9'; // Replace with your economy API
        } else if (category === 'ملتيميديا') {
          apiUrl = 'https://www.tanaghomtech.com/magazine/public/api/article?include=category,writer,country&filter[category_id]=10'; // Replace with your economy API
        } else if (category === 'حقوق وحريات') {
          apiUrl = 'https://www.tanaghomtech.com/magazine/public/api/article?include=category,writer,country&filter[category_id]=11'; // Replace with your economy API
        } else {
          apiUrl = '/api/general'; // Default API
        }

        const response = await fetch(apiUrl);
        const data = await response.json();
        setArticles(data.data);
        console.log("data", data.data);

      } catch (error) {
        console.error('Error fetching articles:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, [category]);

  return (
    <div style={{ margin: '20px' }}>
      <Navbar1 />
      <Topbar />
      <Navbar2 />
      <Box >
        {loading ? (
          <Typography variant="h6" textAlign="center">
            Loading articles...
          </Typography>
        ) : articles.length > 0 ? (
          <Grid container style={{direction:"rtl"}} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            {articles.map((article, index) => (
              <Grid key={index} item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Link style={{textDecoration:"none"}} to={`/article/${article.id}`}>
              <Card
                  variant="outlined"
                  sx={{
                    p: 2,
                    display: 'flex',
                    zIndex: 1,
                    margin: '20px 40px',
                    height: "150px",
                    width: "400px",
                    direction: "rtl"
                  }}
                >
                  <CardMedia
                    component="img"

                    alt={article.title}
                    src={imgUrl + article.imageLink}
                    sx={{
                      borderRadius: '6px',
                      width: "150px",
                    }}
                  />
                  <Box sx={{ alignSelf: 'center', ml: 2 }}>


                    <Typography
                      fontWeight="bold"
                      gutterBottom
                      sx={{
                        wordWrap: 'break-word', // Ensures text wraps properly
                        overflow: article.title.length > 25 ? 'visible' : 'hidden',
                        whiteSpace: article.title.length > 25 ? 'normal' : 'nowrap',
                        padding: "10px"
                      }}
                    >
                      {article.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      fontWeight="regular"
                      sx={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        padding: "10px"
                      }}
                    >
                      {article.writer.writerName}
                    </Typography>

                    <Typography
                      sx={{
                        padding: "10px",
                        fontSize: "12px"
                      }}
                      variant="body2"
                      color="text.secondary"
                      fontWeight="regular"
                    >
                      {moment(article.created_at).format("MMM Do YY")}
                    </Typography>
                  </Box>

                </Card>
              </Link>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography variant="h6" textAlign="center">
            No articles found for "{category}".
          </Typography>
        )}
      </Box>
      <Footer/>
    </div>
  );
};

export default ArticlePage;
