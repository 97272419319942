import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import img1 from "../../../assets/flag.png";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import FacebookIcon from "@mui/icons-material/FacebookRounded";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import CopyrightIcon from "@mui/icons-material/Copyright";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import HeroCard from "../HeroCard/HeroCard";
import backgroundImage from "../../../assets/economy.png";
import firstBuilding from "../../../assets/building1.png";
import secondBuilding from "../../../assets/building2.png";
import thirdBuilding from "../../../assets/building3.png";
import fourthBuilding from "../../../assets/building4.png";
import SectionTitle from "../SectionTItle/SectionTitle";
import useFetch from "../../../hooks/useFetch";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: "white",
  borderRadius: "0",
}));
function Rights() {
  const imgUrl ="https://www.tanaghomtech.com/magazine/storage/app/public/"
 const [newslist] = useFetch("https://www.tanaghomtech.com/magazine/public/api/article?include=category,writer,country&filter[category_id]=11")
  if (!newslist || newslist.length < 5) return null;
  return (
    <>
      <SectionTitle sectionName="حقوق وحريات" />
      <Grid
        id="rights"
        s
        sx={{ marginBottom: "3rem", height: { sx: "auto", md: "100vh" } }}
        container
        spacing={2}
      >
      {newslist?.map(news =>(
         <Grid xs={12} md={6}>
         <HeroCard
           category={news?.category?.categoryName}
           backgroundImage={imgUrl + news.imageLink}
           paragraph={news.brief}
           size="50vh"
           title={news.title}
           categoryColor="#000"
           categoryFontColor="#fff"
           //   backgroundParagaphColor="#f30000"
           paragraphWidth="70%"
         />   
       </Grid>
      ))}
      </Grid>
    </>
  );
}

export default Rights;
