import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import data from "../../../assets/data.png";
import HeroCard from "../HeroCard/HeroCard";
import NewsCard from "../NewsCard/NewsCard";
import data1 from "../../../assets/data1.png";
import data2 from "../../../assets/data2.png";
import data3 from "../../../assets/data3.png";
import data4 from "../../../assets/data4.png";
import data5 from "../../../assets/data5.png";
import SectionTitle from "../SectionTItle/SectionTitle";
import useFetch from "../../../hooks/useFetch";

const Data = () => {
  const imgUrl = "https://www.tanaghomtech.com/magazine/storage/app/public/"
  const [newslist] = useFetch("https://www.tanaghomtech.com/magazine/public/api/article?include=category,writer,country&filter[category_id]=2")
  if (!newslist || newslist.length === 0) return null;
  return (
    <>
      <SectionTitle sectionName="بيانات" />

      <Box
        id="data"
        sx={{
          flexGrow: 1,
          marginTop: "1rem",
          height: { sx: "auto", md: "100vh" },
        }}
      >
        <Grid container spacing={1}>
          {/* Hero card on the left side */}
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <HeroCard
              title={newslist[0]?.title}
              category={newslist[0]?.category.categoryName || "بيانات"} // Access categoryName if category is an object
              date={newslist[0]?.created_at}
              backgroundImage={imgUrl + newslist[0]?.imageLink}
              size="75vh"
              paragraph={newslist[0]?.paragraph}
              categoryColor="#0A6522"
            />
          </Grid>

          {/* News cards stacked vertically on the right side */}
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {newslist?.slice(1).map((article) => (
              <NewsCard
                title={article?.title}
                date={article?.created_at}
                imageUrl={imgUrl + article?.imageLink}
              />
            ))}
          
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Data;
